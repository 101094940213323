<template>
    <div style="height: 100%">
        <tabbed-page
            v-if="currentComp !== 'LeaveDetail'"
            :tabList="tabList"
            @changeTabs="handlerChangeTabs"
        >
        </tabbed-page>
        <keep-alive :include="['StatisticsList', 'RecordList']">
            <component    
                :is="currentComp"
                :query="query"
                @change="handleChangeComp"
                @setPageInitTabs="setPageInitTabs"
            ></component>
        </keep-alive>
    </div>
</template>

<script>
import TabbedPage from './Sub/TabbedPage/index.vue'
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
export default {
    name: "SchoolLeaveRecordAnalysisScrollWrapper",
    components: {
        TabbedPage,
        StatisticsList: () => import(/* webpackChunkName:'StatisticsList' */"@/components/scrollWrapper/SchoolLeaveRecordAnalysis/StatisticsList"),
        RecordList: () => import(/* webpackChunkName:'RecordList' */"@/components/scrollWrapper/SchoolLeaveRecordAnalysis/RecordList"),
        LeaveDetail: () => import(/* webpackChunkName:'LeaveDetail' */"@/components/scrollWrapper/SchoolLeaveRecordAnalysis/LeaveDetail"),
        StatisticsDuringList: () => import(/* webpackChunkName:'StatisticsDuringList' */"@/components/scrollWrapper/SchoolLeaveRecordAnalysis/StatisticsDuringList"),
        LeaveAnalysis: () => import(/* webpackChunkName:'LeaveAnalysis' */"@/components/scrollWrapper/SchoolLeaveRecordAnalysis/LeaveAnalysis"),
    },
    data() {
        return {
            currentComp: "StatisticsList",
            tabList: [
                {
                    id: 0,
                    title: "请假统计",
                    com: "StatisticsList",
                    vHasPermi: ['schoolLeaveRecordAnalysis:analysis'],
                },
                {
                    id: 1,
                    title: "请假记录",
                    com: "RecordList",
                    vHasPermi: ['schoolLeaveRecordAnalysis:record'],
                },
                {
                    id: 2,
                    title: "请假中统计",
                    com: "StatisticsDuringList",
                    vHasPermi: ['schoolLeaveRecordAnalysis:inProgress'],
                },
                {
                    id: 3,
                    title: "请假分析",
                    com: "LeaveAnalysis",
                    vHasPermi: ['schoolLeaveRecordAnalysis:leaveAnalysis'],
                },
            ],
            query: {},
            formData: {},
            type: 'list',
            stuLeaveDetailInfo: {},
        };
    },
    activated() {
        this.init();
        this.setGlobalPageType(this.type)
    },
    created() {
        this.init();
    },
    mounted() {
        if (JSON.parse(sessionStorage.getItem('stuLeaveDetail'))) {
            this.currentComp = 'LeaveDetail'
            this.query = JSON.parse(sessionStorage.getItem('stuLeaveDetail'))
            let type = 'detail'
            this.setGlobalPageType(type)
            // sessionStorage.setItem('stuLeaveDetail', {})
        }
    },
    methods: {
        init () {
            this.tabList = tabPageHasAuth(this.tabList);
            console.log('从哪里跳转过来的哦',this.$route);
            // 如果是从学生状态跳转过来的，重定向url，并携带参数
            if (this.$route.query.isStudentOA) {
                this.stuLeaveDetailInfo = this.$route;
                this.$router.replace({
                    path: '/schoolLeaveRecordAnalysis',
                })
                console.log('this.stuLeaveDetailInfo',this.stuLeaveDetailInfo);
                let myObj = {
                    compName: 'LeaveDetail',
                }
                let obj = Object.assign({}, this.stuLeaveDetailInfo, myObj)
                this.handleChangeComp(obj)
                
            } else {
                if (this.$route.query.isGotoLeaveRecordAnalysis) {
                    // 判断，如果是OA表单详情返回的页面，默认是第二个标签'请假记录'
                    this.currentComp = this.tabList.map(item => item.com)[1]
                    this.handlerChangeTabs( {
                        id: 1,
                        title: "请假记录",
                        com: "RecordList",
                        vHasPermi: ['schoolLeaveRecordAnalysis:record'],
                    })
                } else {
                    this.currentComp = this.tabList.map(item => item.com)[0];
                }
                this.$route.query.isGotoLeaveRecordAnalysis = null
            }
            
        },
        handlerChangeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.currentComp = item.com
                } else {
                    this.$set(item, 'sel', false)
                }
            })
        },
        handlerChangeTab(e) {
            this.currentComp = e.compName;
        },
        handleChangeComp(e) {
            console.log('执行了吗',e);
            
            this.currentComp = e.compName
            this.query = e.query
            let type = e.compName === 'StatisticsList' ? 'list' : 'detail'
            this.setGlobalPageType(type)
        },
        setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
        setPageInitTabs(){

            this.type = 'list'
            this.currentComp = "StatisticsList"
            this.$eventDispatch('setGlobalPageType', 'list')

        }
    },
};
</script>

<style lang="scss" scoped>
.tabs-list {
    border-radius: 4px 0 0 4px;
    display: flex;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background-color: #fff;
    padding-left: 30px;

    .tabs-item {
        position: relative;
        text-align: center;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
        margin-right: 48px;
    }

    .tabs-item.current {
        position: relative;
        color: #3c7fff ;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #3C7FFF;
            border-radius: 1px;
        }
    }
}
</style>
